import React from "react"
import PropTypes from "prop-types"
import { css } from '@emotion/core'

const Icon = ({icon, size, rotate, ...props}) => {
  const TheIcon = icon

  return <TheIcon css={css`
    width: ${size}px;
    max-height: ${size}px;
    transform-origin: 50% 50% 0;
    transform: rotate(${rotate}deg);
`} {...props}/>
}

Icon.defaultProps = {
  rotate: 0,
}

Icon.propTypes = {
  size: PropTypes.oneOf([22, 40, 60]).isRequired,
  icon: PropTypes.func,
}

export default Icon
