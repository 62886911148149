import React from "react"
import PropTypes from "prop-types"
import { useTheme } from "emotion-theming"
import Typography from "./Typography"

const Heading = (props) => {
  const theme = useTheme()
  return <Typography component={`h${props.level}`} css={theme.styles.typography[`h${props.level}`]} {...props}>{props.children}</Typography>
}

Heading.propTypes = {
  ...Typography.propTypes,
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]).isRequired,
}


export default Heading
