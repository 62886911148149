import React from "react"
import styled from "@emotion/styled"
import Icon from "./Icon"
import { css } from "@emotion/core"
import Typography from "./Typography"
import StarIcon from "../assets/svgs/star.inline.svg"
import GitHubIcon from "../assets/svgs/github.inline.svg"
import ForkIcon from "../assets/svgs/share.inline.svg"

const StyledGitHubCard = styled.a`
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  justify-content: space-between;
  color: ${props => props.theme.colors.text};
  
  padding: ${props => props.theme.space[2]}px;
  
  vertical-align: top;
  width: 100%;
  max-width: ${props => props.theme.githubCard.width}px;
  height: ${props => props.theme.githubCard.height}px;
  margin-bottom: ${props => props.theme.githubCard.space}px;
  border: 1px solid ${props => props.theme.colors.text};
  background-color: rgba(255,255,255,0.31);
  box-shadow: 3px 3px 0 ${props => props.theme.colors.text}80;
  
  @media (min-width: ${props => (props.theme.githubCard.width * 2) + props.theme.githubCard.space * 2}px) {
    margin-right: ${props => props.theme.githubCard.space}px;
  }
  
  &:hover {
    p {color: ${props => props.theme.colors.secondary}}
    border: 3px solid ${props => props.theme.colors.secondary};
    background-color: rgba(255,255,255,0.31);
    box-shadow: 3px 3px 0 ${props => props.theme.colors.secondary}80;
  }
`

const StyledBox = styled.div`
  padding-bottom: ${props => props.bottomPadding}px;
`

const StyledReactions = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 20px;
  display: inline-flex;
`

const StyledComments = styled.div`
  position: absolute;
  left: 80px;
  top: 0;
  height: 20px;
  display: inline-flex;
`

const CountStyle = css`
  margin-left: 10px;
`

const SocialStyle = css`
  margin-top: 3px;
`

const GitHubStyle = css`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 40px;
`

const BottomInfo = styled.div`
    position: relative;
    width: 100%;
    height: 20px;
`

const GitHubCard = (props) => {
  return <StyledGitHubCard href={props.url} target={"_blank"} rel={"noopener noreferrer"}>

    <div>
      <StyledBox bottomPadding={20}>
        <Typography weight={"bold"} align={"left"} variant={"smallText"}>
          {props.name}
        </Typography>
      </StyledBox>


      <Typography align={"left"} variant={"card"}>
        {props.description}
      </Typography>
    </div>


    <BottomInfo>
      <StyledReactions>
        <Icon size={22} icon={StarIcon} css={SocialStyle}/>
        <Typography css={CountStyle} align={"left"} variant={"text"}>
          {props.stargazers.totalCount}
        </Typography>
      </StyledReactions>
      <StyledComments>
        <Icon size={22} icon={ForkIcon} css={SocialStyle} rotate={-90}/>
        <Typography css={CountStyle} align={"left"} variant={"text"}>
          {props.forkCount}
        </Typography>
      </StyledComments>
      <Icon css={GitHubStyle} size={40} icon={GitHubIcon}/>
    </BottomInfo>

  </StyledGitHubCard>
}

// GitHubCard
//.propTypes = {}


export default GitHubCard
