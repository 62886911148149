import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Card from "./Card"
import GitHubCard from "./GitHubCard"

const StyledCardGrid = styled.div`
  width: ${props => props.theme.card.width}px;
  margin-bottom: ${props => -props.theme.card.space}px;
  max-width: 100%;
  text-align: left;
  
  @media (min-width: ${props => (props.theme.card.width * 2) + props.theme.card.space * 2}px) {
    width: ${props => (props.theme.card.width * 2) + props.theme.card.space * 2}px;
    margin-right: ${props => -props.theme.card.space}px;
    max-width: none;
  }
`

const CardGrid = (props) => {
  return (
    <StyledCardGrid>
      {
        (props.cardItems && props.cardItems[0].article) ?
        props.cardItems.map(({ article }) => {
          return <Card key={article.url} title={article.title} url={article.url} reactionsCount={article.positive_reactions_count} commentCount={article.comments_count}/>
        }) :
          props.cardItems.map((gitHubRepo) => {
            if(gitHubRepo.stargazers.totalCount < 5) return null

            return <GitHubCard key={gitHubRepo.name} {...gitHubRepo} />
          })
      }
    </StyledCardGrid>
  )
}

CardGrid.propTypes = {
  cardItems: PropTypes.array,
}


export default CardGrid
