import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Heading from "./Heading"
import { css } from "@emotion/core"


const StyledSectionHeader = styled.div`
    position: relative;
`

const IconStyle = css`
  position: absolute;
`

const SectionHeader = (props) => {
  const Icon = props.icon

  return <StyledSectionHeader><Icon css={[IconStyle, {maxWidth: `${props.size}px`, maxHeight: `${props.size}px`, top: `${props.iconTop}px`, left: `${props.iconLeft}px`}]}/><Heading level={2}>{props.title}</Heading></StyledSectionHeader>
}

SectionHeader.propTypes = {
  title: PropTypes.node.isRequired,
  icon: PropTypes.func,
  iconTop: PropTypes.number,
  iconLeft: PropTypes.number,
  size: PropTypes.number
}

export default SectionHeader
