import React from "react"
import PropTypes from "prop-types"
import { css } from '@emotion/core'

const ImgIcon = ({icon, size, rotate, ...props}) => {

  return <img src={icon} alt={props.alt ?? ""} css={css`
    width: ${size}px;
    max-height: ${size}px;
    transform-origin: 50% 50% 0;
    transform: rotate(${rotate}deg);
`} {...props}/>
}

ImgIcon.defaultProps = {
  rotate: 0,
}

ImgIcon.propTypes = {
  size: PropTypes.oneOf([22, 40, 60]).isRequired,
  icon: PropTypes.func,
}

export default ImgIcon
