import React from "react"
import styled from "@emotion/styled"
import Icon from "./Icon"
import { css } from "@emotion/core"
import Typography from "./Typography"
import DevIconInline from "../assets/svgs/devplain.inline.svg"
import ReactionIcon from "../assets/svgs/reaction-stack.svg"
import CommentIcon from "../assets/svgs/comment-bubble.svg"
import ImgIcon from "./ImgIcon"

const StyledCard = styled.a`
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  justify-content: space-between;
  color: ${props => props.theme.colors.text};
  
  padding: ${props => props.theme.space[2]}px;
  
  vertical-align: top;
  width: 100%;
  max-width: ${props => props.theme.card.width}px;
  height: ${props => props.theme.card.height}px;
  margin-bottom: ${props => props.theme.card.space}px;
  border: 1px solid ${props => props.theme.colors.text};
  background-color: rgba(255,255,255,0.31);
  box-shadow: 3px 3px 0 ${props => props.theme.colors.text}80;
  
  @media (min-width: ${props => (props.theme.card.width * 2) + props.theme.card.space * 2}px) {
    margin-right: ${props => props.theme.card.space}px;
  }
  
  &:hover {
    p {color: ${props => props.theme.colors.secondary}}
    border: 3px solid ${props => props.theme.colors.secondary};
    background-color: rgba(255,255,255,0.31);
    box-shadow: 3px 3px 0 ${props => props.theme.colors.secondary}80;
  }
`

const StyledReactions = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 20px;
  display: inline-flex;
`

const StyledComments = styled.div`
  position: absolute;
  left: 80px;
  top: 0;
  height: 20px;
  display: inline-flex;
`

const CountStyle = css`
  margin-left: 10px;
`

const SocialStyle = css`
  margin-top: 3px;
`

const DevIconStyle = css`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 20px;
`

const BottomInfo = styled.div`
    position: relative;
    width: 100%;
    height: 20px;
`

const Card = (props) => {
  return <StyledCard href={props.url} target={"_blank"} rel={"noopener noreferrer"}>
    <Typography align={"left"} variant={"card"}>
      {props.title}
    </Typography>
    <BottomInfo>
      <StyledReactions>
        <ImgIcon size={22} icon={ReactionIcon} css={SocialStyle}/>
        <Typography css={CountStyle} align={"left"} variant={"text"}>
          {props.reactionsCount}
        </Typography>
      </StyledReactions>
      <StyledComments>
        <ImgIcon size={22} icon={CommentIcon} css={SocialStyle}/>
        <Typography css={CountStyle} align={"left"} variant={"text"}>
          {props.commentCount}
        </Typography>
      </StyledComments>
      <Icon css={DevIconStyle} size={40} icon={DevIconInline}/>
    </BottomInfo>
  </StyledCard>
}

// Card.propTypes = {}


export default Card
