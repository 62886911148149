import React from "react"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Typography from "../components/Typography"
import Heading from "../components/Heading"
import Icon from "../components/Icon"
import DevToIconInline from "../assets/svgs/dev.to.inline.svg"
import GitHubIconInline from "../assets/svgs/github.inline.svg"
import LinkedInIconInline from "../assets/svgs/linked_in.inline.svg"
import TwitterIconInline from "../assets/svgs/twitter.inline.svg"
import ArticlesIconInline from "../assets/svgs/articles.inline.svg"
import RocketsIconInline from "../assets/svgs/rocket.inline.svg"
import SectionHeader from "../components/SectionHeader"
import CardGrid from "../components/CardGrid"


const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 680px;
  text-align: center;
  padding: 82px 0 ${props => props.theme.space[5]}px 0;
`

const StyledBox = styled.div`
  padding: ${props => props.padding}px;
`

const StyledInline = styled.div`
  display: flex;
  justify-content: center;
    align-items: flex-end;

  margin-right: -${props => props.gutter}px;
  margin-bottom: -${props => props.gutter}px;
  
  ${props => props.gutter ? `
    > * {
      margin-right: ${props.gutter}px;
      margin-bottom: ${props.gutter}px;
    }
  ` : ``}

`

const StyledExternalLink = styled.a`
    cursor: pointer;
    height: 40px;
    display: inline-flex;
    align-items: flex-end;
    justify-content: flex-end;
`

const ExternalLink = ({children, withoutStyle, ...props} ) => withoutStyle ?
  <a target={"_blank"} {...props} rel="noopener noreferrer"
     style={{ cursor: "pointer" }}>{children}</a>
  :
  <StyledExternalLink {...props} target={"_blank"}
                      rel="noopener noreferrer">{children}</StyledExternalLink>

const IndexPage = ({ pageContext }) => {
  const devArticles = pageContext.devArticles
  const githubRepos = pageContext.githubRepos

  return (
    <Layout>
      <SEO title="Home"/>
      <Container>
        <Typography variant={"h3"}>
          Hi, I'm
        </Typography>

        <Heading level={1}>Henrik Wirth</Heading>
        <StyledBox padding={40}>
          <StyledInline gutter={40}>
            <ExternalLink href={"https://twitter.com/HenrikWirth"} title={"Henrik on Twitter"}><Icon size={40} icon={TwitterIconInline}/></ExternalLink>
            <ExternalLink href={"https://github.com/henrikwirth"} title={"Henrik on GitHub"}><Icon size={40} icon={GitHubIconInline}/></ExternalLink>
            <ExternalLink href={"https://dev.to/henrikwirth"} title={"Henrik on Dev.to"}><Icon size={40} icon={DevToIconInline}/></ExternalLink>
            <ExternalLink href={"https://www.linkedin.com/in/henrik-wirth/"} title={"Henrik on LinkedIn"}>
              <Icon size={40} icon={LinkedInIconInline}/>
            </ExternalLink>
          </StyledInline>
        </StyledBox>

        <Typography variant={"text"} max={460}>
          I love doing Web, App, Software, AR, Design.
        </Typography>

        <StyledBox padding={20}>
          <Typography variant={"text"} max={460}>
            A Developer and sometimes a little bit of everything,
            at <ExternalLink href={"https://nevernull.io"} withoutStyle={true}>nevernull.io</ExternalLink>
          </Typography>
        </StyledBox>

        <StyledBox padding={40}>
          <SectionHeader icon={ArticlesIconInline} alt={"Article Icon"} size={50} iconLeft={-70} iconTop={18} title={"Articles"}/>
        </StyledBox>

        <CardGrid cardItems={devArticles}/>

        <StyledBox padding={20}>
          <Typography variant={"text"} max={460}>
            <ExternalLink withoutStyle={true} href={"https://dev.to/henrikwirth"}>More Articles ...</ExternalLink>
          </Typography>
        </StyledBox>

        <StyledBox padding={40}>
          <SectionHeader icon={RocketsIconInline} alt={"Rocket Icon"} size={70} iconLeft={-75} iconTop={10} title={"GitHub Projects"}/>
        </StyledBox>

        <CardGrid cardItems={githubRepos}/>


        {/*<Typography variant={"text"} max={460}>*/}
        {/*  Coming soon ...*/}
        {/*</Typography>*/}


        <StyledBox padding={80}>
        </StyledBox>

      </Container>
    </Layout>
  )
}

export default IndexPage
